.examDownloadButton {
  display: flex;
  flex-direction: row;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 10px;
}
.buttonTitle {
  display: flex;
  background-color: $light_cyan;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 10px;
  padding-top: 3px;
  width: 70%;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;
  justify-content: space-between;
}
.buttonLink {
  background-color: $blue;
  color: $pure_white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  font-weight: bold;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: bold !important;
  @include mq(550px, max) {
    height: 52px;
  }
}
.icon {
  padding-right: 10px;
  filter: brightness(0) invert(1);
}
.buttonText {
  text-decoration: none;
  color: white;
}
