.today-events-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  cursor: pointer;
  &:hover {
    background-color: rgba(233, 248, 253, 0.6);
  }
  &:not(:last-child) {
    border-bottom: 1px solid $white2;
    margin-bottom: 8px;
  }
  & > .label {
    font-size: 16px;
    font-weight: 500;
    color: $blue;
    display: flex;
    & > :first-child {
      margin-right: 4px;
    }
  }
  & > .description {
    font-size: 15px;
    color: $lighter_black;
  }
  & > .author {
    font-size: 14px;
    color: $grey;
  }
}
