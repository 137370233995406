.subs-item-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // border-bottom: 1px solid $white2;
  margin-right: 10px;

  .label {
    //color: $blue;
    //text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-top: 3px;
    text-align: center;
  }
  .MuiLinearProgress-root {
    height: 6px;
    border-radius: 6px;
  }
  .due-date {
    color: $grey_dark;
    text-align: center;
    font-size: 11px;
  }
}
