.full-page {
  .rejected-request {
    // background: #f7a89e6b;
  }
  .not-verified {
    .information {
      & > span {
        font-weight: bold !important;
        font-size: 18px;
        color: #a2a2a7;
        & > span {
          margin-left: 0.5rem;
          font-size: 17px;
          color: #ec5542 !important;
          & > span {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
