.transferLink {
  .transferText {
    color: #0d0e0f;
    text-align: center;
    font-family: tajawal;
    font-weight: bold;
    font-size: 14px;
    opacity: 1;
    text-transform: none;
  }
  .transferIcon {
    height: 40px;
    width: 48px;
    padding-right: 8px;
  }

  .transferDescription {
    color: #0d0e0f;
    text-align: center;
    font-family: tajawal;
    font-size: 14px;
    opacity: 1;
    text-transform: none;
    justify-content: center;
    align-items: center;
  }
  .link {
    color: #18698d;
    font-weight: bold;
    text-decoration: underline;
  }
}
