.bank-account-item {
  border: 1px solid #34c0ff;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
  width: auto;
  @include mq(1280px, max) {
    min-width: 220px;
    margin-bottom: 0;
    margin-right: 12px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  & > .title {
    color: $darker_blue;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px;
    display: flex;
  }
  & > div {
    display: flex;
    flex-direction: column;
    & > span {
      color: $grey;
      font-size: 12px;
      font-weight: 500;
      & > span {
        color: $darker_blue;
      }
    }
  }
}
