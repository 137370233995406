@import './Loading/loading';
@import './Logo/logo';
@import './MenuDropdown/menuDropdown';
@import './ActionLink/actionLink';
@import './Button/taButton';
@import './Modals/modals';
@import './Card/card';
@import './ViewTitle/viewTitle';
@import './ActionsGroup/actionsGroup';
@import './TodayEventsWidget/todayEventsWidget';
@import './TodayEventsItem/todayEventsItem';
@import './CardTitle/cardTitle';
@import './ResponsiveTable/responsiveTable';
@import './PointsHistory/pointsHistory';
@import './Empty/empty';
@import './PointsHistory/pointsHistory';
@import './ShowMoreLink/showMoreLink';
@import './BankAccountsItem/bankAccountsItem';
@import './BankAccountsList/bankAccountsList';
@import './WalletDetails/walletDetails';
@import './WalletServices/walletServices';
@import './WalletWidget/walletwidget';
@import './TransferLink/transferLink';
@import './VariantButton/variantButton';
@import './PaymentMethodsItem/paymentMethodsItem';
@import './PaymentMethodsList/paymentMethodsList';
@import './PointsTransferButton/point-transfer.scss';
@import './WalletBalance/walletBalance';
@import './MyProgressWidget/myProgressWidget';
@import './MyProgressItem/myProgressItem';
@import './AddChildButton/AddChildButton';
@import './ChildItem/ChildItem';
@import './ChildList/ChildList';
@import './AttachementsSession/attachment';
@import '../pages/Profil/ProfileWidget/profileWidget';
@import '../pages/Profil/SecurityWidget/securityWidget';
@import './SubscriptionItem/subscriptionItem';

//Blue Button
.blueButton {
  @include blueButton();
  @media screen and (max-width: 425px) {
    width: 25% !important;
  }
  @media screen and (max-width: 350px) {
    width: 15% !important;
  }
  .buttonText {
    @include buttonText();
  }
}

.modalLine {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: $blue;
}

.link {
  text-decoration: none;
  color: black;
  transition: all 300ms ease;
  &.primary {
    color: #2ba7df;
    &:hover {
      color: #16739c;
    }
  }
}

.noselect {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
