.ExamDetailsContainer {
  box-shadow: 0px 3px 10px #2ba7df29 !important;
  margin-top: 15px;
  width: 100%;
  .cardContent {
    .cardHeader {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 5px;
    }

    .card {
      display: flex;
      justify-content: flex-start;
      //width: 480px;

      .customCard {
        //width: 486px;
        height: 100%;
        border-radius: 10px;
        box-shadow: none;
        display: flex;
        text-transform: none;
        border: 1px rgba(0, 0, 0, 0.096) solid;

        .examsSubject {
          background-color: $blue;
          color: white;
        }

        .teacherName {
          color: gray;
          font-weight: Bold !important;
        }

        .examDate {
          color: rgb(177, 3, 3);
          padding-bottom: 10px;
        }

        .examDetails {
          display: flex;
          justify-content: space-between;
        }

        .text {
          padding-bottom: 5px;
          font-weight: Bold !important;
        }

        .submitFiles {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}
