.attachment {
  display: flex;
  padding-top: 10px;
  height: auto;
}

.attachmentTitle {
  background-color: $light_cyan;
  max-height: 35px;
  width: 100%;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  overflow: hidden;

  img {
    padding: 15px;
  }

  .attachmentName {
    //padding: 15px 0px;
    font-size: 14px;
    text-shadow: 0 0 0 black;
    text-overflow: ellipsis;
  }
}

.attachmentButton {
  background-color: $blue;
  border-radius: 0px 5px 5px 0px;
  max-height: 35px;
  display: flex;
  align-items: center;

  .downloadIcon {
    filter: brightness(0) invert(1);
    padding: 0 5px;
  }

  .buttonText {
    padding-right: 7px;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}
