.childs-container {
  display: flex;
  margin-bottom: 20px;
  position: relative !important;
  overflow: hidden;
  height: auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  padding: 12px;
  padding-right: 16px;
  padding-left: 0;
}
.childs-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  overflow-x: scroll;
  scrollbar-width: none !important;
  position: absolute !important;
  width: 92% !important ;
  margin-left: 100px;

  @include mq(950px, max) {
    overflow-x: scroll;
    scrollbar-width: none !important;
    position: absolute !important;
    width: 70% !important ;
    margin-left: 100px;
  }
}

//   .ta-Childs-responsive {
//     display: flex;
//     flex-direction: row;
//     flex-wrap:initial;
//     //width: auto;
//     //position: relative;
//     @include mq(1280px, max) {
//       height: 85px;
//     }
// }
