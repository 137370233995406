@import './components/subjectWidget';
@import './components/subjectTabs';

.subjects-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  & > :nth-child(n) {
    margin-top: 0 !important;
    margin-bottom: 12px;
  }
  @include mq(1766px, min) {
    & > :nth-child(5n + 1),
    :nth-child(5n + 2),
    :nth-child(5n + 3),
    :nth-child(5n + 4) {
      display: flex;
      margin-right: 12px;
    }
  }
  @include mqs(1766px, 1380px) {
    & > :nth-child(4n + 1),
    :nth-child(4n + 2),
    :nth-child(4n + 3) {
      display: flex;
      margin-right: 12px;
    }
  }
  @include mqs(1380px, 980px) {
    & > :nth-child(3n + 1),
    :nth-child(3n + 2) {
      display: flex;
      margin-right: 12px;
    }
  }
  @include mqs(980px, 780px) {
    & > :nth-child(2n + 1) {
      display: flex;
      margin-right: 12px;
    }
  }
  @include mqs(780px, 600px) {
    & > :nth-child(n) {
      display: flex;
      margin-right: 0;
    }
  }
  @include mqs(600px, 460px) {
    & > :nth-child(2n + 1) {
      display: flex;
      margin-right: 12px;
    }
  }
  @include mq(460px, max) {
    & > :nth-child(n) {
      display: flex;
      margin-right: 0;
    }
  }
  & > .MuiLink-root {
    width: calc(100% / 5 - 48px / 5);
    // max-width: 257px;
    @include mqs(1766px, 1380px) {
      width: calc(100% / 4 - 36px / 4);
      // max-width: 284px;
    }
    @include mqs(1380px, 980px) {
      width: calc(100% / 3 - 24px / 3);
      // max-width: 256px;
    }
    @include mqs(980px, 780px) {
      width: calc(100% / 2 - 12px / 2);
      // max-width: 226px;
    }
    @include mqs(780px, 600px) {
      width: calc(100%);
      //   max-width: 248px;
      margin: 0 auto;
      margin-bottom: 12px;
    }
    @include mqs(600px, 460px) {
      width: calc(100% / 2 - 12px / 2);
    }
    @include mq(460px, max) {
      width: calc(100%);
      //   max-width: 248px;
      margin: 0 auto;
      margin-bottom: 12px;
    }
    height: 148px;
    & > .MuiCard-root {
      padding: 20px 16px;
      height: 100%;
      transition: all 300ms ease;
      &:hover {
        background: rgba(233, 248, 253, 0.6);
      }
      & > .subject-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        & > img {
          height: 60px;
          margin-bottom: 8px;
        }
        & > .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin: 0;
          & > .label {
            font-size: 16px;
            color: #18698d;
          }
          & > .progress-nu {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
            color: #4a3c98;
          }
        }
        & > .MuiLinearProgress-root {
          width: 100%;
          height: 6px;
          margin: 0;
          margin-top: -15px;
          // & > .MuiLinearProgress-bar {
          //   background: #fe726e;
          // }
        }
      }
    }
  }
}

.subjects-container {
  @include mq(1380px, min) {
    padding-right: 24px !important;
  }
  @include mqs(1380px, 1200px) {
    padding-right: 24px !important;
  }
  @include mq(1200px, max) {
    padding-right: 0 !important;
  }
}

.detailed-card {
  padding: 2px;
  & > .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12px;
    padding-top: 18px;
    & > img {
      height: 80px;
    }
    & > .label {
      font-size: 20px;
      font-weight: 500;
      color: #18698d;
      margin-left: 12px;
    }
  }
}
