.MuiToolbar-root {
  .MuiAutocomplete-root {
    .MuiInputBase-root {
      padding: 0;
      padding-right: 24px;
      & > .MuiInputBase-input {
        margin-left: 42px;
        min-width: fit-content;
        @include mq(713px, max) {
          min-width: auto;
        }
      }
      & > .MuiOutlinedInput-notchedOutline {
        border: none;
        outline: none;
      }
    }
  }
}

.autocopmlete-item {
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-bottom: 1px solid #d6e0ec;
  width: 100%;
  transition: all 300ms ease;
  text-decoration: none;
  color: black;
  & > .title {
    font-size: 14px;
    color: #000;
  }
  & > .subtitle {
    font-size: 12px;
    color: #a9a9ae;
    transition: all 300ms ease;
  }
  &:hover > .title {
    color: #18699c;
  }
}

.searchbar {
  & > .MuiFormControl-root {
    & > .MuiInputBase-root {
      padding-right: 0 !important;
      // & > .MuiInputBase-input {
      // }
      & > .MuiAutocomplete-endAdornment {
        display: none;
      }
    }
  }
}

.search-main {
  border-radius: 10px !important;
  max-width: 655px !important;
  @include mq(1920px, min) {
    width: 888px !important;
  }
  @include mqs(1920px, 1420px) {
    width: 100% !important;
    margin-right: 94px !important;
  }
  @include mqs(1420px, 1200px) {
    width: 100% !important;
    margin-right: 64px !important;
  }
  @include mqs(1200px, 960px) {
    width: 100% !important;
    margin-right: 34px !important;
  }
  @include mq(960px, max) {
    width: 100% !important;
    margin-right: 24px !important;
  }
}
