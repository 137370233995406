.calendar-responsive {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  height: 600px;
}
.calendar-container {
  display: flex;
  position: absolute;
}

.ta-calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  & > .header {
    display: flex;
    min-width: 680px;
    & > .navigation {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      & > .current-week {
        color: #0d0e0f;
        font-size: 20px;
        margin: 0 12px;
      }
    }
    & > .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
  & > .body {
    display: flex;
    margin-top: 12px;
    position: relative;
    margin-left: 40px;
    & > .timeline {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 92px;
      left: -40px;
      & > .time {
        color: #adadb2;
        font-size: 12px;
        margin-bottom: 13px;
      }
    }
    & > .day {
      border-top: 2px solid #e7e7e8;
      border-bottom: 2px solid #e7e7e8;
      border-left: 1px solid #e7e7e8;
      border-right: 1px solid #e7e7e8;
      width: calc(100% / 7);
      min-width: 126px;
      & > .header {
        display: flex;
        flex-direction: column;
        padding: 4px;
        border-bottom: 2px solid #e7e7e8;
        & > .name {
          color: #8bb4c6;
          text-transform: capitalize;
          font-size: 20px;
          font-weight: 600;
        }
        & > .sub-name {
          color: #d0d0d3;
          font-weight: 600;
        }
      }
      & > .divider {
        background: #f8f8fd;
        height: 10px;
        border-bottom: 2px solid #e7e7e8;
      }
      & > .time-zone {
        height: 30px;
        border-bottom: 2px solid #e7e7e8;
        position: relative;
        & > .event {
          position: absolute;
          border: 1px solid #3aaaff;
          box-shadow: 0px 2px 8px rgb(58 170 255 / 20%);
          background: white;
          border-radius: 4px;
          width: 100%;
          height: 60px;
          z-index: 12;
          padding: 2px 6px;
          overflow: hidden;
          cursor: pointer;
          transition: all 300ms ease;
          &.done {
            border: 1px solid #3dec37;
            box-shadow: 0px 2px 8px rgb(61 236 55 / 20%);
          }
          &:hover {
            box-shadow: 0px 1px 2px rgb(58 170 255 / 60%);
            z-index: 90 !important;
            width: 99% !important;
            &.done {
              box-shadow: 0px 1px 2px rgb(61 236 55 / 60%);
            }
          }
          & > .name {
            font-size: 12px;
            color: #584ba0;
          }
          & > .row {
            display: flex;
            justify-content: space-between;
            & > .time {
              font-size: 10px;
              color: #b2b2b6;
            }
            & > .MuiChip-root {
              border-radius: 4px;
              font-size: 8px;
              height: 15px;
              color: white;
              background: black;
              & > .MuiChip-label {
                padding: 4px;
              }
              &.primary {
                background: #2ba7df;
              }
              &.success {
                background: #3dec37;
              }
              &.danger {
                background: #ed5454;
              }
            }
          }
        }
      }
      & > :last-child {
        border-bottom: none;
      }
      &.today {
        border: 2px solid #18698d;
        & > .header {
          & > .name {
            color: #18698d;
          }
          & > .sub-name {
            color: #a2a2a7;
          }
        }
      }
    }
    & > :first-child {
      border-left: 2px solid #e7e7e8;
    }
    & > :nth-last-child(2) {
      border-right: 2px solid #e7e7e8;
    }
  }
}

.status-icon {
  &.MuiChip-root {
    border-radius: 4px;
    margin-left: 0;
    background: white;
    color: #2ba7df;
    & > .MuiChip-label {
      display: flex;
      padding: 8px;
    }
  }
  &.danger {
    color: #ed5454;
  }
  &.rotate {
    & > .MuiChip-label {
      & > .MuiSvgIcon-root {
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
      }
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
