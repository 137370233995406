.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none !important;
  padding-bottom: 0 !important;
  background: #f1f1f7;
  & > .MuiCircularProgress-colorPrimary {
    color: #2ba7df;
  }
}
