.profile-widget-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
  & > :last-child {
    margin-top: 0 !important;
  }
  & > .header {
    color: $blue;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid $white2;
    padding-bottom: 6px;
    margin-bottom: 6px;
    & > .bold {
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  & > .label {
    font-size: 12px;
    color: $grey;
    text-align: center;
    margin-top: 2px !important;
  }
  & > .action-subs-btn {
    color: white;
    border: 1px solid white;
    box-shadow: 0px 0 8px 0 #ffffff8c;
    padding: 2px 8px;
    text-transform: none;
    margin-top: 8px !important;
  }
  & > hr {
    border: none;
    border-bottom: 1px solid $white2;
    width: 100%;
    margin-top: 8px !important;
  }
}

.profile-infos {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  text-align: center;
  & > .inline-btns-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    & > * {
      font-size: 12px;
      padding: 2px;
    }
    & > .subs-btn {
      background: $lighter_blue;
      color: $darker_blue;
      box-shadow: 0px 0 8px 0 #ffffff8c;
    }
    & > a > .action-subs-btn {
      color: white;
      border: 1px solid white;
      box-shadow: 0px 0 8px 0 #ffffff8c;
      padding: 2px 8px;
      text-transform: capitalize;
    }
  }
  & > .MuiBadge-root {
    width: fit-content;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 8px;
    & > .MuiAvatar-root {
      width: 92px !important;
      height: 92px;
    }
  }
  & > .text-muted {
    color: gray;
    & > .text-dark {
      color: black;
      font-weight: 500;
    }
  }
  & > .text-blue {
    color: $blue;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
  }
  & > .mt {
    margin-top: 8px;
  }
  & > img {
    width: 80px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 16px;
  }
  & > hr {
    border: none;
    border-bottom: 1px solid #dddddd;
    width: 100%;
  }
  & > .header-bg {
    position: absolute;
    width: 140%;
    height: 100px;
    background: transparent linear-gradient(180deg, #40bcf9, #1e87f0) 0 0 no-repeat padding-box;
    top: -25px !important;
    left: -25px;
    z-index: 0;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      bottom: -134px;
      left: -34px;
      width: 80%;
      height: 200%;
      transform: matrix(0.91, -0.42, 0.42, 0.91, 0, 0);
      box-shadow: 4px -4px 32px 4px rgba(30, 135, 240, 0.2);
      opacity: 0.8;
      border-radius: 50%;
      z-index: -9;
      background: transparent linear-gradient(200deg, #40bcf9, #1e87f0) 0 0 no-repeat padding-box;
    }
  }
  & > :not(:last-child) {
    z-index: 2;
  }
}
