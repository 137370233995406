@import 'examCard';
@import 'examDetails';
@import 'examDownloadButton';

.exam-card-content {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .card-header {
    display: flex;
    justify-content: space-between;

    .filterText {
      padding-top: 8px;
      padding-right: 5px;
    }
  }
}
.cards {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.examButton {
  @include blueButton();
  @media screen and (max-width: 425px) {
    width: calc(70%) !important;
    //float: left !important;
  }
  //@media screen and (max-width: 350px) {
  //  width: 170px !important;
  //  float: left !important;
  //}
  //@media (max-width: 425px) and (min-width: 351px) {
  //  margin-left: 65px;
  //}
  .examButtonText {
    @include buttonText();
  }
}
