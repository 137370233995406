.ta-btn {
  border-radius: 0 !important;
  text-transform: none !important;
  background: #ffffff;
  height: 32px !important;
  transition: all 300ms ease;
  color: #2ba7df;
  width: 150px;

  /* rounded */
  &.btn-rounded {
    border-radius: 4px !important;
    margin-left: 10px;
  }
  &.onlyIcon {
    & > .MuiButton-label > .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &.dark {
    background: #18698d;
    color: #ffffff;
    &:hover {
      background: #0c5677;
    }
    &.Mui-disabled {
      color: rgb(255 255 255 / 40%);
      background: rgb(24 105 141 / 60%);
    }
  }
  &.primary {
    background: #2ba7df;
    color: #ffffff !important;
    &:hover {
      background: #248bb9;
    }
  }
  &.grey {
    background: #ffffff;
    color: #2ba7df;
    &:hover {
      background: #edf2f5;
    }
  }
  &.blue {
    background: #ffffff;
    color: #2ba7df;
    &:hover {
      background: #ffffff !important;
    }
  }

  // &.secondary {
  // }
  &.success {
    background: #50bf88;
    color: #ffffff;
    // color: #363636;
    &:hover {
      background: #40a070;
    }
  }
  // &.info {
  // }
  &.warning {
    background: #edc454;
  }
  &.danger {
    background: #ed5454;
    color: #ffffff;
    &:hover {
      background: #d25050;
    }
  }
  &.navigation {
    background: #ffffff;
    color: #ed5454;
    &:hover {
      background: #edf2f5;
    }
  }

  &.MuiButton-outlined {
    background: transparent;
    border: 1px solid;
    border-color: #c6cbd4;
    color: #363636;
    &.dark {
      border-color: #18698d;
      color: #ffffff;
    }
    &.primary {
      border-color: #2ba7df;
      color: #2ba7df;
    }
    &.success {
      border-color: #50bf88;
      color: #50bf88;
    }
    &.warning {
      border-color: #edc454;
      color: #edc454;
    }
    &.danger {
      border-color: #ed5454;
      color: #ed5454;
      &:hover {
        background: #ed545412;
      }
    }

    & > .MuiButton-label > span > .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
      font-size: 14px !important;
    }
  }

  &.Mui-disabled {
    &.primary {
      background: white;
      opacity: 0.8;
    }
  }

  &.btn-lg {
    height: 40px !important;
    padding: 6px 32px;
    border: 2px solid #2ba7df;
  }
  &.btn-xl {
    width: auto;
  }

  &.white {
    height: 40px !important;
    background: #ffffff;
    width: auto;
    box-shadow: none;
    border: 1px solid #6c757d;
    color: rgb(131, 130, 130) !important;
    margin-bottom: 10px;
    &:hover {
      background: #6c757d;
      box-shadow: none;
      color: white !important;
    }
  }
}

.mt-12 {
  margin-top: 12px !important;
  margin-left: 10px !important;
  margin-bottom: 12px !important;
}
.bt-responsive {
  @include mq(950px, max) {
    font-size: 14px !important;
    height: 45px !important;
  }
}
