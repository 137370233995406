.table-responsive {
  margin-top: 20px;
  width: 100%;
  //   overflow: auto;
  height: auto;
  position: relative;

  & > .table-container {
    max-height: 580px;
    overflow: auto;
    position: absolute;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(245, 245, 245, 0.6) !important;
  }
  & > .MuiTablePagination-root {
    margin-top: 340px;
    position: absolute;
  }
  & th,
  td {
    min-width: max-content;
    white-space: nowrap;
    border: 0.5px solid #ebebf3;

    padding: 2px 4px;
    text-align: left;
    font-weight: bold;
    td:last-child {
      text-align: right !important;
    }
  }
  & .MuiTableCell-stickyHeader {
    // background-color: #bfe4f5 !important;
    background-color: #ebebf3 !important;
    padding: 2px 6px;
    color: #ababb0;
    text-align: left;
  }
  td:last-child {
    text-align: right !important;
  }
  .MuiTableCell-stickyHeader:last-child {
    text-align: right !important;
  }
  & .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(245, 245, 245, 0.6);
  }
  .tab-nav-link {
    text-decoration: none;
    color: black;
  }
}
.loading {
  height: 60px;
}
.ta-column-expand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .sessionInfo-expand {
    display: flex;
    width: 30% !important;

    flex-direction: column !important;
    align-items: flex-start;
  }
  .sessionDocuments-expand {
    width: 40% !important;
    left: 10;
  }
}
.home-page {
  height: auto !important;
  position: relative;
  & > .table-container {
    // max-height: 320px !important;
    overflow: auto;
    position: relative;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(245, 245, 245, 0.6) !important;
  }
  & > .MuiTablePagination-root {
    margin-top: 20px !important;
    position: relative !important;
  }
}
