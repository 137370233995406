.Child-item {
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  width: 270px;
  height: 100px;
  min-width: 270px;
  justify-content: center;
  align-items: right;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;

  filter: drop-shadow(0px 2px 8px rgb(179 208 232 / 60%));
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @include mq(950px, max) {
    height: 90px !important;
    margin-right: 15px;
    margin-left: 5px;
  }

  &.active {
    background: transparent linear-gradient(90deg, #79cffa, #1e87f0) 0 0 no-repeat padding-box;
    color: #ffffff;
    &::before {
      background: #2ba7df;
    }
    &::after {
      border-top-color: #2ba7df;
    }
  }
  & > .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 10px;
    top: 20px;
    @include mq(950px, max) {
      top: 10px;
    }
  }
  .child-Info {
    //  min-width: 120px;
    left: 70px;
    top: 7px;
    position: absolute;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap !important;
    //  margin-right: 20px;
    // margin-left: 20px;
    align-items: flex-start;

    // @include mq(inside, 280px, 950px){
    //   font-size: 12px;
    //  }

    .name {
      font-size: 15px;
      font-weight: bold !important;
      //   width: 200px;

      @include mq(950px, max) {
        font-size: 13px !important;
      }
    }
    .id {
      font-size: 13px !important;
      font-weight: bold !important;
      flex-wrap: wrap !important;
      //   width: 200px;
    }
    .delete-btn {
      margin: 20px;
    }

    @include mq(413px, max) {
      padding-left: 26px;
    }
  }
}
