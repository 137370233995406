.login-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  margin: 150px 15px;
  .centered-box {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 24px;
    //border-radius: 8px 0px 0px 8px;
    //margin: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    min-width: 700px;
    min-height: 430px;
    align-items: center;
    justify-content: center;
    .log-box {
      width: 70%;
      align-items: center;
    }
    .reset {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > .title {
      font-size: 25px;
      font-weight: 500;
      text-align: center;
      margin-bottom: -25px;
      margin-top: 50px;
    }
    .reset-password {
      margin-top: 5px;
      color: $grey3 !important;
      font-size: 14px !important;
      justify-content: center !important;
      align-items: center !important;
      text-decoration: underline dotted;
      text-decoration-skip-ink: none;
      text-align: center;

      cursor: help;
    }

    & > .label {
      font-size: 16px;
      font-weight: 500;

      & > a {
        color: $grey;
        text-decoration: none;
        transition: all 300ms ease;

        &:hover {
          color: $grey_dark;
        }
      }
    }

    & > .input {
      &:focus {
        border: 2px solid $blue1;
      }
    }

    & > .MuiButton-root {
      background: $lighter_blue;
      color: $darker_blue;
      margin: 8px 0;
      padding: 3px;

      &:hover {
        background: $lighter_blue;
      }
    }

    .input-card {
      border: 0px solid transparent;
      position: relative;
      border-radius: 4px;
      padding: 0px 4px 0px 4px;
      margin: 5px 0;
      min-height: 47px;
      width: 90%;
      background-color: $white4;
      display: flex;
      .input {
        border: 2px solid transparent;
        border-radius: 4px;
        width: 100%;
        background-color: $white4;
      }

      .icon-input {
        margin: 25px 10px;
        font-size: 16px;
        color: #495057;
      }
    }

    .submit-login {
      background: transparent !important;
      color: $grey3;
      padding: 6px 12px;
      font-weight: 400;
      border-color: $grey3;
      font-size: 15px;
      margin: 10px 0px;
      span {
        text-transform: inherit !important;
      }
      &:hover {
        background: $grey3 !important;
        color: white;
      }
    }

    .text-muted {
      color: $grey3;
      font-size: 16px;
      justify-content: center;
      align-items: center;
    }

    .social-login {
      display: flex;
      justify-content: center;
      align-items: center;

      .login-google {
        margin: 0 5px;
        color: #dc3545;
        border-color: #dc3545;
        font-size: 13px;

        .google-icon {
          margin-right: 8px;
          width: 15px;
        }

        &:hover {
          background: #dc3545;
          color: white;

          .google-icon {
            filter: brightness(0) invert(1);
          }
        }
      }

      .login-fb {
        margin: 0 5px;
        border-color: #4d96db;
        color: #4d96db;
        font-size: 13px;

        &:hover {
          background: #4d96db;
          color: white;

          .fb-icon {
            filter: brightness(0) invert(1);
          }
        }

        .fb-icon {
          margin-right: 4px;
          fill: $blue;
          width: 12px;
        }
      }

      @media screen and (max-width: 300px) {
        flex-direction: column;
        .login-fb {
          margin-top: 10px;
        }
      }
    }
    @media screen and (max-width: 751px) {
      width: calc(100% - 100px);
      min-width: 100%;
    }
  }

  .login-info {
    width: 330px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../../../src/assets/img/shapes.png'),
      linear-gradient(135deg, #2ba7df 0%, #007bff 100%) !important;
    color: white;
    background-repeat: no-repeat;
    box-shadow: rgba(0, 0, 0, 0.24) 4px 0px 8px;
    & > img {
      width: 110px;
      position: relative;
    }
    //background-size: cover;
    .login-description {
      display: flex;
      flex-direction: column;
      margin: 10px;
      align-items: center;

      .description-title {
        font-size: 30px;
        justify-content: center;
      }

      .description-text {
        justify-content: center;
        text-align: center;
      }
    }
    .nav-button {
      color: white;
      border-color: white;
      //text-transform: lowercase;
      font-weight: 400;
      &:hover {
        background-color: white;
        color: black;
      }
    }
    @media screen and (max-width: 1200px) {
      width: 300px;
    }
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .select-input-card {
    border: 2px solid transparent;
    border-radius: 4px;
    padding: 4px;
    margin: 8px 0;
    min-height: 47px;
    width: 60%;
    background-color: $white4;
    display: flex;
    align-items: center;

    .register-select {
      width: 90% !important;
      margin: 0 auto;
      border-bottom: 0;
      > * input {
        font-size: 13px !important;
      }
    }
    .icon-input {
      margin: 10px 10px;
      font-size: 16px;
      color: #495057;
    }
  }
  .card-description {
    margin: 10px;
  }
  .resend-code {
    color: gray;
    cursor: pointer;
    text-decoration: underline;
  }
}
