.bt-card {
  display: flex;
  justify-content: center;
  padding: 12px;
  position: relative;
  align-items: center;
  width: 90px;
  height: 100px;
  border: 2px solid #2ba7df !important;
  border-radius: 15% !important;
  margin-right: 20px;
  box-shadow: 2px 8px 8px rgba(179, 208, 232, 0.6) !important;
  @include mq(950px, max) {
    top: 4px !important ;
    height: 80px;
    width: 80px;
  }

  .bt-content {
    justify-content: center;
    align-items: center;
    vertical-align: bottom !important;
    padding: 22px;
  }
  .border {
    display: flex;
    border: 2px solid #2ba7df !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    justify-content: center !important;
    align-items: center !important;
    @include mq(950px, max) {
      height: 30px;
      width: 30px;
    }
  }
  .add-icon {
    color: #2ba7df;
    font-weight: normal;
  }
  @include mq(950px, max) {
    font-size: 10px;
  }
}
