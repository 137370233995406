.card-title {
  color: $blue;
  font-size: 20px;
  font-weight: 500;
  &.dark-blue {
    color: $darker_blue;
  }
  &.orange {
    color: $orange;
  }
  &.underline {
    text-decoration: underline;
  }
  &.underlined {
    &::before {
      content: '';
      width: 100px;
      height: 2px;
      background: #40ccfe;
      position: absolute;
      margin-top: 17px;
      border-radius: 70px;
    }
  }
  &.last-courses {
    &::before {
      width: 194px;
    }
  }
  &.free-videos {
    &::before {
      width: 132px;
    }
  }
  &.forum-title {
    &::before {
      background: #40fe86;
      width: 60px;
    }
  }
}

.MuiSvgIcon-root {
  font-size: 20px !important;
  &.orange {
    color: $orange !important;
  }
}
